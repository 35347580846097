// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  /** primary **/
  --ion-color-primary: #ed5c66 ;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #d75a64;
  --ion-color-primary-tint: #ed5c66;

  /** secondary **/
  --ion-color-secondary: #008489;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #008489;
  --ion-color-secondary-tint: #008489;

  /** tertiary **/
  --ion-color-tertiary: #26326c;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #26326c;
  --ion-color-tertiary-tint: #26326c;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: white;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #232629;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: transparent;
  --ion-color-light-tint: #ffffff;



  /** facebook **/
  --ion-color-facebook: #4064ad;
  --ion-color-facebook-rgb: 64, 100, 173;
  --ion-color-facebook-contrast: white;
  --ion-color-facebook-contrast-rgb: 0, 0, 0;
  --ion-color-facebook-shade: #d7d8da;
  --ion-color-facebook-tint: #f5f6f9;

}

ion-toolbar{
  --background: transparent;
}
.header-ios ion-toolbar:last-child {
  --border-width: 0;
}

ion-item{
  border:none!important;
}

ion-item {
  --background: transparent !important;
  background-color: transparent !important;
}

.tab-selected {
  border-bottom: solid;
}


.list-md{
  background-color: transparent;
}

.list-ios{
  background-color: transparent;
}

.item-native{
  background-color: transparent;
}

.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);

}
.ion-color-light {
  border: solid 1px #B0B0B0;
  border-radius: 10px;
}

ion-checkbox {
  --background-checked: #008489;
  --border-color-checked: #008489;
  --checkmark-color: white;
}

ion-radio{
  --color-checked: #008489;
}
.ios ion-radio {
  border: solid 1px #d0d0d0;
  height: 25px;
  width: 25px;
  padding-bottom: 5px;
  border-radius: 50px;
  margin: 0;
  margin-right: 10px;
}

.button-native.sc-ion-back-button-ios{
  background-color: transparent !important;
}
.button-native.sc-ion-back-button-md{
  background-color: transparent !important;
}

ion-buttons{
  cursor: pointer;
}


//@media only screen and (min-height: 768px) and (min-width: 770px) {
//  .sc-ion-modal-md-h {
//    --width: 800px;
//    --height: 800px;
//  }
//  .sc-ion-modal-ios-h {
//    --width: 800px;
//    --height: 800px;
//  }
//}
