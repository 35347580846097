// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

body {
  font-family: "Poppins", sans-serif !important;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

html {
  font-family: "Poppins", sans-serif !important;
}

:root {
  --ion-font-family: "Poppins", sans-serif;
}

:root[dir="rtl"] {

  html {
    direction: rtl;
  }

  p {
    text-align: right;
    direction: rtl;
  }

  span {
    text-align: right;
    direction: rtl;
  }

  div {
    text-align: right;
    direction: rtl;
  }

  ul {
    list-style-type: arabic-indic;
    direction: rtl;
    text-align: right
  }

  li {
    list-style-type: arabic-indic;
    direction: rtl;
    text-align: right
  }
}

:root[dir="ltr"] {}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input:disabled {
  background-color: #e9ecef !important;
}


/* Cookie bar */
.alert {
  padding: 5px !important;
  font-size: 11pt;
}

.cookiealert {
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  margin: 0 !important;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  transform: translateY(100%);
  transition: all 500ms ease-out;
  color: #ecf0f1;
  background: #324e5c;
}

// Modal backdrop styling
.custom-modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

// Modal panel styling
.modal-panel {
  background-color: #fff;
  overflow: hidden;
  border-radius: 1rem;

  &.FullScreen {
    border-radius: 0;
  }
}

.cookiealert.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0%);
  transition-delay: 1000ms;
}

.cookiealert a {
  text-decoration: underline
}

.cookiealert .acceptcookies {
  vertical-align: baseline;
}

@media screen and (min-width: 770px) {
  .cookiealert {
    bottom: 0px !important;
  }
}

.text-muted {
  font-size: 9pt;
  color: #000000 !important;
  opacity: 0.4 !important;
  line-height: 1.3 !important;
  font-style: italic !important;
}

.imgProfileRounded {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  line-height: 25px;
  text-align: center;
  background-color: #666f7a;
  background-size: cover;
  position: relative;
  display: inline-block;
  border-radius: 100%;
  max-width: inherit;
  background-position: center !important;
  background-size: cover !important;
}

.imgProfile {
  width: 30px;
  height: 30px;
  line-height: 34px;
  text-align: center;
  background-color: #666f7a;
  display: inline-block;
  border-radius: 100%;
  max-width: inherit;
}


/*Side Menu*/

.sideMenu {
  display: none;
  width: 80%;
  overflow-y: auto;
  max-width: 400px !important;
  background-color: white;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 99999;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px !important;
}

.sideMenuOpened {
  display: block;
}

.btnSidebarIcon {
  background-color: white;
  font-size: 14pt;
  color: black;
}

.backSideMemu {
  z-index: 99998;
  cursor: pointer;
  background-color: #0000003d;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
}


/*Header desktop handlers*/

#mainRouterOutlet {
  margin-top: 65px;
}

#mainHeader {
  display: block;
  top: 0;
  position: absolute;
  width: 100%;
  z-index: 9;
}

.mainContentWithHeader {
  padding-top: 64px;
}

#footerTabBar {
  display: none;
  background-color: white;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, .1)
}

.hideSmallMobile {
  display: block;
}

.hideMobile {
  display: block;
}

.invertColumnInMobile {
  flex-direction: inherit;
}

.hideDesktop {
  display: none;
}

.hideMobileImportantOnlyMobile {
  display: block;
}

.hideMobileImportant {
  display: block !important;
}

.hideDesktopImportant {
  display: none !important;
}

.showTablet {
  display: none;
}

.hideTablet {
  display: block;
}

.mainContentDesktop {
  padding-bottom: 60px;
  width: 100%;
  max-width: 1450px;
  margin: auto;
  /*
  @media screen and (min-width: 1680px) {
    max-width: 1550px;
  }

  @media screen and (min-width: 1920px) {
    max-width: 1680px;
  }

  @media screen and (min-width: 2560px) {
    max-width: 1920px;
  } */
}

.mainContentDesktopListingCreation {
  padding-bottom: 60px;
  max-width: 800px;
  margin-right: auto;
  margin-left: 15px;
}


.mainContentDesktopThinner {
  padding-top: 15px;
  padding-bottom: 60px;
  max-width: 900px;
  margin: auto;
}

.mainContentDesktopWider {
  padding-top: 15px;
  padding-bottom: 60px;
  margin: auto;
}

.mainContentListingPage {
  padding-top: 15px;
  padding-left: 15px;
  padding-bottom: 0px;
  width: 100%;
  max-width: 1500px;
  margin: auto;
}

.sideBarContentDesktop {
  max-width: 300px;
  margin: auto;
  margin-top: 20px;
  background-color: #ffffff;
  padding: 25px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
  border-radius: 5px;
  position: sticky;
  top: 25px;
}

.popover-header {
  margin: 0 !important;
}


//if very small mobile
@media screen and (max-width: 360px) {
  .hideSmallMobile {
    display: none !important;
    ;
  }
}

//if mobile
@media screen and (max-width: 767px) {
  #mainRouterOutlet {
    margin-top: 60px;
  }

  #mainHeader {
    display: none;
  }

  #footerTabBar {
    display: block;
  }

  .hideMobile {
    display: none;
  }

  .invertColumnInMobile {
    flex-direction: column-reverse;
  }

  .hideDesktop {
    display: block;
  }

  .hideMobileImportant {
    display: none !important;
  }

  .hideMobileImportantOnlyMobile {
    display: none !important;
  }

  .hideDesktopImportant {
    display: block !important;
  }

  .mainContentDesktop {
    padding-top: 0;
  }

  .mainContentDesktopListingCreation {
    margin-left: 0;
  }

  .mainContentListingPage {
    padding: 0 !important;
  }

  .fb_customer_chat_bubble_animated_no_badge {
    bottom: 47pt !important;
  }

  .mainContentDesktopThinner {
    padding: 10px 20px;
  }

  .mainContentWithHeader {
    padding-top: 0px;
  }

}

//if tablet
@media screen and (min-width: 767px) and (max-width: 1024px) {
  .showTablet {
    display: block;
  }

  .hideTablet {
    display: none;
  }
}


.badgeDirectBooking {
  position: absolute;
  z-index: 9;
  background-color: #ffffff;
  font-size: 8pt;
  right: 5px;
  /* font-weight: 600; */
  top: 5px;
  padding: 6px 8px;
  border: 1px solid #ededed;
  box-shadow: 0 10px 20px rgba(55, 57, 86, 0.1), 0 5px 5px rgba(59, 55, 86, 0.03);
  color: #244250;
  border-radius: 50px;
  -webkit-transform: translate3d(0, 0, 0);
  display: -webkit-flex;
}

.badgeDirectBooking i {
  margin-right: 5px;
}

.badgeDirectBooking b {
  padding-left: 5px;
}

.badgeRealUser {
  font-size: 8pt;
  padding: 5px 10px;
  color: #1e8489;
  border: solid 1px;
  border-radius: 50px;
  margin-right: 10px;
}

.badgeRealUser i {
  margin-right: 5px;
}

/* Listing creation page */

.listingCreationTypeImg {
  opacity: 0.9;
}

.modalContainer {
  padding: 10px;
  height: 100%;
  background-color: #fbfbfb !important;
}

.saveBtnModal {
  border-radius: 5px;
  font-size: 18px;
  text-transform: initial;
  float: right;
}

.saveBtnModal i {
  margin-right: 5px;
}

.deleteBtnModal {
  border-radius: 5px;
  font-size: 18px;
  text-transform: initial;
  float: right;
}

.deleteBtnModal i {
  margin-right: 5px;
}


/* Listing page */
.listingPageMainImgContainer {
  width: 100%;
  height: 350px;
  background-position: center !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  background-color: white;
  overflow: hidden;
  position: relative;
}


.listingPageMainImg {
  position: relative;
}

.flagImg {
  height: 15px;
  margin: 5px;
  width: 25px;
}

/*MAPPA*/

.mapContainer {
  position: relative;
  background-position: center !important;
  background-size: cover !important;
  margin: auto;
  border-radius: 5px;
  width: 100%;
  height: 150px;
}

.mapContainer span {
  color: #000000;
  font-size: small;
  background-color: #ffffff;
  padding: 10px;
  bottom: 15px;
  right: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  position: absolute;
  border-radius: 5px;
}


.imgMapPin {
  top: 10px !important;
  left: -25px;
  text-align: center;
  z-index: 1;
  background-color: white;
  border-radius: 28px;
  color: #244250;
  height: 28px;
  justify-content: center;
  padding: 0px 8px;
  position: relative;
  white-space: nowrap;
  font-size: 11pt;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  line-height: 28px;
  box-shadow: grey 0px 0px 5px;
  font-weight: 500;
  cursor: pointer;
}

.imgMapPin:hover {
  color: #18878d !important;
}

.lisitingHover {
  font-size: 13pt !important;
  background-color: #008489 !important;
  color: white !important;
  z-index: 3060 !important;
}

//if not mobile
@media screen and (min-width: 480px) {

  .modalContainer {
    padding: 25px;
  }

  .listingPageMainImgContainer {
    height: 500px;
    background-color: black;
    text-align: center;
    box-shadow: 0px -2px 14px -2px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-top: 6px;
  }

  .listingPageMainImg {
    height: 500px !important;
    width: auto !important;
    max-width: none !important;
  }

  ion-modal.stack-modal {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  }

  .smScreenModal {
    --width: 40%;
    --height: 40%;
    --max-width: 400px;
  }

  .confirmScreenModal {
    --width: 30%;
    --height: 35%;
    --max-width: 500px;
    --min-width: 300px;
    --min-height: 300px;
  }

  .mdScreenModal {
    --width: 70%;
    --height: 70%;
    --max-width: 800px;
  }

  .copyEmailMessageModal {
    --width: 75%;
    --height: 75%;
    --max-width: 800px;
    --max-height: 680px !important;
    --border-radius: 1rem;
  }

  .roundedModal {
    --border-radius: 1rem;
  }

  .lgScreenModal {
    --width: 90%;
    --height: 90%;
  }

  .fullScreenModal {
    --width: 90%;
    --height: 90%;
  }

  .fullScreenVerticalModal {
    --width: 90%;
    --height: 90%;
    --max-width: 800px;
  }

  .newUserModal {
    --width: 90%;
    --height: 90%;
    --max-width: 1000px;
    --border-radius: 1rem;
  }

  .lgScreenVerticalModal {
    --width: 90%;
    --height: 90%;
    --max-width: 1400px;
    --border-radius: 1rem;

    .modal-wrapper {
      border-radius: 1rem;
    }
  }

  .mapContainer {
    background-position: center !important;
    background-size: cover !important;
    margin: auto;
    border-radius: 5px;
    width: 600px;
    height: 300px;
  }

}

/*GMAPS*/
.gm-style .gm-style-iw-d {
  overflow: auto !important;
}

.gm-style .gm-style-iw-c {
  padding: 0 !important;
}

.gm-ui-hover-effect {
  display: none !important;
}

.gmnoprint div {
  border-radius: 10px !important;
}

/*END Listing page */


/* Visits */

.backgroundColorPayed {
  background-color: #137b2e;
}

.colorPayed {
  color: #137b2e !important;
}

.backgroundColorConfirmed {
  background-color: #3eaf61;
}

.colorConfirmed {
  color: #3eaf61 !important;
}

.backgroundColorBooked {
  background-color: #fece04;
}

.colorBooked {
  color: #fece04 !important;
}

.backgroundColorRequested {
  background-color: #FF9800;
}

.colorRequested {
  color: #FF9800 !important;
}

.backgroundColorAccepted {
  background-color: #07a4ff;
}


.backgroundColorInstantToBeConfirmed {
  background-color: #cddc39;
}

.colorAccepted {
  color: #07a4ff !important;
}

.backgroundColorExpired {
  background-color: #bdbdbd;
}

.colorExpired {
  color: #bdbdbd !important;
}

.backgroundColorCancelled {
  background-color: #d75a64;
}

.colorExpiredCancelled {
  color: #d75a64 !important;
}

.animationRoomlessBtn {
  animation: play1 2s ease infinite;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

@keyframes play1 {

  0% {
    transform: scale(1);
  }

  15% {
    box-shadow: 0 0 0 2px rgba(0, 132, 137, 0.40);
  }

  25% {
    box-shadow: 0 0 0 3px rgba(0, 132, 137, 0.40), 0 0 0 4px rgba(0, 132, 137, 0.40);
  }

  35% {
    box-shadow: 0 0 0 5px rgba(0, 132, 137, 0.40), 0 0 0 6px rgba(0, 132, 137, 0.40);
  }

}


/* Virtual call */

.animationIncomingCall {
  animation: play2 1.5s ease infinite;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

@keyframes play2 {

  0% {
    transform: scale(1);
  }

  15% {
    box-shadow: 0 0 0 2px rgba(237, 92, 102, 0.4);
  }

  25% {
    box-shadow: 0 0 0 5px rgba(237, 92, 102, 0.4), 0 0 0 8px rgba(237, 92, 102, 0.4);
  }

  25% {
    box-shadow: 0 0 0 10px rgba(237, 92, 102, 0.4), 0 0 0 15px rgba(237, 92, 102, 0.4);
  }

}

/*END Virtual call */


:host(.button-solid.ion-color) .button-native {
  padding-top: 4px;
}


.backgroundPrimary {
  background-color: #eb626c;
}

.backgroundGray {
  background-color: #92949c;
}

.backgroundBlack {
  background-color: #484848;
}

.colorPrimary {
  color: #eb626c !important;
}

.colorSecondary {
  color: #008489 !important;
}

.backgroundSecondary {
  background-color: #368187 !important;
}

.backgroundWhite {
  background-color: white !important;
  color: black !important;
}

.colorSecondary.negative-delta {
  color: #F0626F;
}

.header-translucent-md {
  background-color: white;
}

.footer-md::before {
  display: none !important;
}

.footer-translucent-md {
  background-color: white;
}

.header-translucent-ios {
  background-color: #f4f4f4d4
}

.footer-translucent-ios {
  background-color: #f4f4f4d4
}

ion-card {
  margin: 10px !important;
}

.featured {
  border: solid #3a87ff 1px;
  border-radius: 5px;
  padding: 2px;
}

a {
  text-decoration: none;
}

/* Btn add footer */
.plusBtnFooter {
  bottom: 5px;
}

ion-toolbar h1 {
  margin: 0;
  font-size: 14pt;
  padding-left: 10px;
}

ion-toolbar {
  --border-style: none;
}


ion-fab-list button.ion-fab {
  overflow: visible;
  position: relative;
  background-color: #FFF;
  margin-bottom: 20px;
  border-radius: 50px;
  color: #444;
  line-height: 0;
  font-size: 22px;
  width: 50px;
  height: 50px;
  box-shadow: rgba(0, 0, 0, 0.36) 0px 2px 4px 0px !important;

  div.label {
    position: absolute;
    left: 55px;
    color: #3e3e3e;
    top: 13px;
    font-size: 15px;
    height: 10px;
    line-height: 1;
    text-align: left;
    font-weight: normal;
  }
}

.backdrop {
  width: 100%;
  height: 100%;
  z-index: 999;
  position: absolute;
  animation-duration: 0.2s;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  background-color: #ffffff7d;
}

.backdrop:before {
  content: '';
  background: inherit;
  position: absolute;
  left: -25px;
  right: -100px;
  top: -25px;
  bottom: 0;
}

ion-content>.scroll-content {
  padding-bottom: 56px;
}

.headerHidden {
  transition: height 1s ease-out;
  display: none;
  height: 0;
  overflow: hidden;
}

.headerShown {
  display: block;
  transition: height 1s ease-out;
  visibility: inherit;
  height: auto;
  overflow: auto;
}

.featuredIcon {
  position: absolute;
  color: #4393ff;
  border: solid #4393ff 1px;
  border-radius: 25px;
  font-size: 20px;
  left: 20px;
  top: 20px;
  padding: 3px;
  width: 28px;
  text-align: center;
  background-color: white;
}

.featuredIconLargeColumn {
  position: absolute;
  color: #4393ff;
  border: solid #4393ff 1px;
  border-radius: 25px;
  font-size: 20px;
  left: 20px;
  top: 20px;
  padding: 3px;
  width: 28px;
  text-align: center;
  background-color: white;
}

.searchbar-ios {
  background-color: #ffffff !important;
  -webkit-transition: box-shadow 200ms ease-in !important;
  -moz-transition: box-shadow 200ms ease-in !important;
  transition: box-shadow 200ms ease-in !important;
  border: 1px solid #EBEBEB !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  height: 48px !important;
}

.searchbar-ios.searchbar-animated .searchbar-search-icon,
.searchbar-ios.searchbar-animated .searchbar-input {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  text-overflow: ellipsis !important;
  font-weight: 800 !important;
  font-size: 17px !important;
}

.searchbar-ios .searchbar-search-icon {
  position: absolute;
  width: 21px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: 15px;
  background-position-x: center;
}

.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
  font-size: 10pt !important;
  margin: 0;
}

.item.sc-ion-label-ios-h,
.item .sc-ion-label-ios-h {
  font-size: 10pt !important;
  margin: 0;
}

.native-textarea.sc-ion-textarea-ios {
  height: auto !important;
}

ion-button {
  border-radius: 5px;
}

.native-textarea sc-ion-textarea-ios {
  height: 100% !important;
}

.searchPopover {
  width: 100%;
}

.searchPopover .popover-content {
  top: 120px !important;
  width: 100%;
  left: 0 !important;
  border-radius: 0;
  max-height: 50%;
  background: transparent;
}

.filtersBadge {
  color: #ec6f70;
  display: inline-block;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  font-size: 12pt;
}

.popoverImg .popover-content {
  top: 30% !important;
  width: 100%;
  max-height: 60%;
  left: 0 !important;
  border-radius: 0;
  background: transparent !important;
}


.circularLittleImg {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background-position: center !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #ffffff;
  left: 30px;
  position: absolute;
  top: 2px;
  z-index: 9999;
}

/*spinner inline*/
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  background-color: transparent;
}

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #afafaf;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(19px, 0);
  }
}

/*spinner inline*/
.bold {
  font-weight: bold;
}

.reviewsBox {
  font-size: larger;
  color: #f4b34d;
  position: absolute;
  font-weight: bold;
  top: 0;
  right: 0;
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;

}

.reviewsBoxList {
  font-size: medium;
  color: #f4b34d;
  position: absolute;
  font-weight: bold;
  top: 5px;
  right: 5px;
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;

}

.reviewsBoxLittle {
  font-size: small;
  color: #ffffff;
  font-weight: bold;
  margin-right: 5px;
  background-color: #f4b34d;
  border-radius: 5px;
  padding: 5px;
  width: fit-content;
  box-shadow: 0 3px 9px 3px rgba(0, 0, 0, 0.05) !important;
  text-align: center;
}

.reviewsNoBox {
  font-size: small;
  color: #f4b34d !important;
  font-weight: bold;
  margin-right: 5px;
  border-radius: 5px;
  padding: 5px;
  width: fit-content;
  text-align: center;
}

.starReview {
  font-size: large;
  margin-left: 2px;
  margin-right: 5px;
  color: #f4b34d !important;
  position: relative;
  top: 1px;
}

.starReviewList {
  font-size: medium;
  margin-left: 2px;
  margin-right: 5px;
  color: #f4b34d !important;
  position: relative;
  top: 1px;
}

.starReviewSmall {
  font-size: small;
}

.reviewCounter {
  color: #6b6b6b;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  margin-top: 10px;
}

.reviewCounterWhite {
  font-size: 14px;
  font-weight: 800;
  line-height: 1;
}


.typesListHorizontal {
  margin: 5px;
  margin-bottom: 0px;
  display: inline-block;
  width: 100px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: #9c3c43;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  cursor: pointer !important;
  display: inline-block;
  padding-right: 12px;
  position: relative;
  text-align: center;
  width: auto;
  background: none;
  font-weight: 800;
  outline: none;
  text-decoration: none;
  transition: background 0.3s ease 0s, border-color 0.3s ease 0s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.typesListHorizontalWhite {
  margin: 5px;
  margin-bottom: 0px;
  display: inline-block;
  width: 100px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: white;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  cursor: pointer !important;
  display: inline-block;
  padding-right: 12px;
  position: relative;
  text-align: center;
  width: auto;
  background: none;
  font-weight: 800;
  outline: none;
  text-decoration: none;
  transition: background 0.3s ease 0s, border-color 0.3s ease 0s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectedtypesListHorizontal {
  border-bottom: solid !important;
  border-bottom-width: 3px !important;
  text-overflow: unset;
}

.selectedtypesListHorizontalWhite {
  color: white !important;
  border-bottom: solid white 5px !important;
  opacity: 1;
  text-overflow: unset;
}


.titleSectionFilter {
  display: inline !important;
  font-size: 16pt;
}

.titleSectionFilterBasic {
  display: inline !important;
  font-size: 12pt;
}

.iconSectionFilter {
  font-size: 22px;
}

.filterSection {
  margin-bottom: 10px;
}

.notWideFilterSection {
  max-width: 400px
}

.filterSectionBasic {
  margin-bottom: 5px;
}


.text-input {
  border: solid #cccccc 1px;
  border-radius: 15px;
  padding: 10px
}

.chipsSelected {
  background: #e6efff;
}

.chipsUnselected {
  background: #f1f1f1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.item-ios.item-block .item-inner {
  border: none;
}


.item-datetime .input-wrapper {
  border: solid #cccccc 1px;
  border-radius: 15px;
  margin-top: 5px;
}

ion-datetime {
  padding: 5px !important;
  padding-left: 10px !important;
}

.datetime-placeholder {
  font-size: 11pt;
}

.datetime-text {
  font-size: 11pt;
  color: #4889ff;
  font-weight: 700;
}

ion-select {
  max-width: none !important;
}

.select-text {
  padding-top: 5px !important;
}


.btnShareLink {
  padding: 13px 15px 13px 15px;
  border-radius: 100px;
  color: white !important;
  background-color: black;
}

.iconFull {
  color: white !important;
}

.logo-facebook {
  color: #3B5998 !important;
}

.logo-instagram {
  color: #E1306C !important;

}

.logo-twitter {
  color: #1dcaff !important;

}

.logo-pinterest {
  color: rgb(230, 0, 35) !important;
}

.logo-youtube {
  color: #F44336 !important;
}

.logo-google {
  color: #F44336 !important;
}

.logo-reddit {
  color: #ff4500 !important;
}

.logo-snapchat {
  color: #FFEB3B !important;
}

.logo-tumblr {
  color: #34526f !important;
}


.hideIcon {
  display: none;
}

.item.sc-ion-label-ios-h,
.item .sc-ion-label-ios-h {
  margin-bottom: 0 !important;
}

ion-checkbox {
  margin: 0 !important;
}

.ion-ios-add-outline {
  font-size: 35px !important;
  position: relative;
  bottom: 1px !important;
  height: fit-content !important;
  background-color: #5294ff !important;
  font-weight: 700;
  color: white !important;
  border-radius: 50px !important;
  padding: 7px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .1), 0 1px 3px 0 rgba(0, 0, 0, .08) !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .1), 0 1px 3px 0 rgba(0, 0, 0, .08) !important;
}

.btnAdd {
  position: relative;
  bottom: 1px !important;
  background-color: #5294ff !important;
  font-weight: 700;
  color: white !important;
  border-radius: 50px !important;
  padding: 11px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .1), 0 1px 3px 0 rgba(0, 0, 0, .08) !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .1), 0 1px 3px 0 rgba(0, 0, 0, .08) !important;
}


.card-content-md {
  line-height: 1.2;
}

.card-content-ios {
  line-height: 1.2;
  padding-top: 15px;
}


ion-input {
  border-bottom: solid 1px #d5d3d7;
  font-size: 10pt !important;
  font-style: italic;
  color: #2b2b2b !important;
}

.native-input {
  padding: 0 !important;
}

.sc-ion-input-md {
  height: 100%;
}

.sc-ion-input-ios {
  // Add any specific styles for iOS here if needed
}

.input-field-container .native-input {
  padding: 1px !important;
  font-style: normal;
}


.listCard {
  margin-bottom: 10px;
  margin-bottom: 10px;
  height: 200px !important;
  width: 100%;
  box-shadow: none !important;
}

.listCardNoFixedHeight {
  margin-bottom: 10px;
  border: none;
  width: 100%;
  box-shadow: none !important;
}


.listingTitle {
  overflow-wrap: break-word !important;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 20px !important;
  line-height: 1.2em !important;
  color: #484848 !important;
  margin: 0px !important;
  font-weight: 500 !important;
}

.listingTitle::first-letter {
  text-transform: uppercase;
}

.listingLocation {
  overflow-wrap: break-word !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  line-height: 1.375em !important;
  color: #848484 !important;
  margin: 0px !important;
  text-transform: capitalize;
}

.listingLocationMapText {
  overflow-wrap: break-word !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: 1.375em !important;
  color: #848484 !important;
  text-transform: capitalize;
  margin: 5px !important;
}


ion-infinite-scroll-content {
  text-align: unset;
}

.infinite-loading {
  margin-bottom: 100px;
  text-align: center;
}


.sc-ion-card-ios-h {
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, .12);
  box-shadow: 0 3px 10px rgba(0, 0, 0, .12);
}

.sc-ion-card-md-h {
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, .12);
  box-shadow: 0 3px 10px rgba(0, 0, 0, .12);
}


:host {
  --background: transparent !important;
  --background-activated: transparent;
  --background-focused: transparent;
  --color: transparent;
  --color-activated: transparent;
  --color-focused: transparent;
  --transition: none;
}

ion-content {
  --background: white !important;
}

.button-native {
  --border-radius: 25px;
  --background: white;
}


.header-md::after {
  display: none;
}

.topContent {
  position: absolute;
}


.btnFloatingRightDiv {
  display: inline;
  float: right;
}

.btnFloatingLeftDiv {
  display: inline;
  float: left;
}

.button-native {
  box-shadow: none !important;
}


page-listingList {
  width: 100%;
}

.pageSection {
  background: #fff;
  padding: 20px;
  margin-bottom: 25px;
  -webkit-transition: -webkit-box-shadow .25s ease-in-out;
  transition: -webkit-box-shadow .25s ease-in-out;
  transition: box-shadow .25s ease-in-out;
  transition: box-shadow .25s ease-in-out, -webkit-box-shadow .25s ease-in-out;
  border-radius: 3px;
}

.native-textarea.sc-ion-textarea-md {
  min-height: 50px !important;
}

.divisor {
  border-bottom-width: var(--border-rule-border-width, 1px) !important;
  border-bottom-color: #cecece !important;
  border-bottom-style: solid !important;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 15px;
  margin-right: 15px;
}

.sectionContainerNotWhiteBackground {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  background: #fff;
  padding: 20px;
  margin-bottom: 25px;
  transition: box-shadow .25s ease-in-out;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .07);
  border-radius: 3px;
}


/* Images cached style */
.imgCachedAsBackground {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
}

.backgroundListingImgCached {
  width: 100% !important;
  min-width: 100%;
  height: auto;
  max-height: fit-content !important;
  opacity: 0.9;
}

.backImgCached {
  min-height: 100px !important;
  position: relative;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  display: initial !important;
}

.backImgBiggerCached {
  width: 100%;
  min-height: 150px !important;
}

.backImgSquaredAndLogoCached {
  height: 200px !important;
  width: auto !important;
  min-width: 100%;
  max-width: unset !important;
}

.backImgListCache {
  position: relative;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  height: 90px;
  width: auto !important;
  min-width: 100%;
  max-width: none !important;
}


.imgAirbnbHomeType {
  width: 100% !important;
  height: auto !important;
  max-height: unset !important;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  display: initial !important;
}

.shadedImgCached {
  opacity: 0.7;
}

.categoryImgCached {
  position: relative !important;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
}

.logoImgCache {
  width: 60px !important;
  height: 60px !important;
  border-radius: 55px;
  border: solid white 2px;
}

.mylistingHomeImgCached {
  width: auto !important;
  height: 100% !important;
  max-width: unset !important;
}

.listingPageImgMyListingCached {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  height: 450px;
  min-width: 100% !important;
  width: auto !important;
  opacity: 0.6;
  max-width: unset !important;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  display: initial !important;
}

.buttonsFooterTabs {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  text-align: center;
  background: none;
}

.buttonsFooterTabs button {
  width: 100%;
  background-color: transparent;
  color: #8c8c8c;
  outline: none;
  display: inline-grid;
  padding: 0 !important;
  height: 40px;
  position: relative;
}

.buttonsFooterTabs img {
  height: 20px;
  margin: auto;
}

.buttonsFooterTabs i {
  font-size: 20px;
  margin-top: 2px;
}

.buttonsFooterTabs span {
  font-size: 7pt;
  text-transform: uppercase;
  padding-top: 5px;
}

.selectedTab {
  color: #ec626c !important;
}

.selectedTab i {
  color: #ec626c !important;
}

.selectedTab span {
  color: #ec626c !important;
}


.addBtn {
  font-size: 20pt;
  padding: 10px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 50px;
  background-color: #ec636c;
  color: white;
}

.selectedTabOwner {
  color: #008489 !important;
}

.selectedTabOwner i {
  color: #008489 !important;
}

.selectedTabOwner span {
  color: #008489 !important;
}


.addBtn {
  font-size: 20pt;
  padding: 10px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 50px;
  background-color: #ec636c;
  color: white;
}


/* Slider immagini ionic */

.swiper-pagination-bullet-active {
  height: 10px !important;
  width: 10px !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet {
  height: 8px;
  width: 8px;
  background: white;
  opacity: 0.6;
  margin: 0 !important;
  margin-right: 4px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}


/* Notification badge */

.notifBadge {
  height: 20px;
  width: 20px;
  background-color: #ec646f;
  color: white;
  border-radius: 47px;
  line-height: 2.5;
  font-size: 6pt;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
  position: absolute;
  right: 5px;
  bottom: 15px;
}

.notifBadgeGreen {
  height: 20px;
  width: 20px;
  background-color: #008489;
  color: white;
  border-radius: 47px;
  line-height: 2.5;
  font-size: 6pt;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
  position: absolute;
  right: 5px;
  bottom: 15px;
}

//if not mobile
@media screen and (min-width: 770px) {

  .notifBadge {
    height: 25px;
    width: 25px;
    display: inline-block;
    background-color: #ec646f;
    color: white;
    border-radius: 47px;
    line-height: 2.5;
    font-size: 8pt;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
    right: 5px;
    top: 5px;
  }

  .notifBadgeGreen {
    height: 25px;
    width: 25px;
    display: inline-block;
    background-color: #008489;
    color: white;
    border-radius: 47px;
    line-height: 2.5;
    font-size: 8pt;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
    right: 5px;
    top: 5px;
  }
}


.noteField {
  border: 0;
  height: 80px;
 // min-width: 200px;
  padding: 10px;
  background-color: lightgoldenrodyellow;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 8pt;
  line-height: 1.3;
  white-space: pre-line;

  &.sm {
    max-width: 150px;
    min-width: 100px;
  }

  &.md {
    max-width: 200px;
    min-width: 150px;
  }
}

.noteField__admin {
  height: 60px;
  width: 200px;
  overflow: auto;
}

/*Calendar*/

.picker__frame {
  min-height: auto !important;
}

.monthview-container ion-slides {
  background-color: #f9f9f9;
}

.swiper-slide-active {
  width: 100% !important;
}

.monthview-eventdetail-timecolumn {
  width: 80px !important;
  font-size: 8pt !important;
}

.monthview-selected .dayCalendar {
  background-color: #008489 !important;
  color: white;
  margin-top: -4px;
  margin-bottom: -3px;
  border-radius: 100%;
  display: inline-block;
  width: 25px;
  line-height: 25px;
  height: 25px;
  vertical-align: middle;
}

.monthview-primary-with-event {
  color: black !important;
}

.text-muted {
  opacity: 0.3;
}

.calendar-day {
  border-radius: 6px;
  padding: 3px 5px;
}

.dayWithEvents {
  font-weight: bold;
  text-decoration: none !important;
  color: #1e8489 !important;
  padding: 5px 10px;
  position: relative;
}

.dayWithEvents i {
  position: absolute;
  top: 4px;
  font-size: 8pt;
  right: 2px;
  color: #1e8489;
}


.text-muted .dayWithoutEvents {
  display: none;
}

.dayWithoutEvents {
  font-weight: bold;
  text-decoration: none !important;
  opacity: 1;
  color: #6c6c6c;
  background-color: #ffffff;
  border: solid 1px gainsboro;
  padding: 5px 0px;
  width: 100%;
}

.passedDate {
  opacity: 0.2 !important;
}

.todayCalendar {
  background-color: #1e848938 !important;
}

.todayCalendarNoBooking {
  color: #ec646f;
  font-weight: bold !important;
}

.dayWithoutEvents:hover {
  background-color: rgba(30, 132, 137, 0.12) !important;
}


.monthview-datetable {
  border: 0 !important;

  thead {
    color: #028489;
  }

  small {
    font-weight: 900;
    font-size: 10pt;
    text-transform: capitalize;
  }

  td,
  th {
    border: 0 !important;
    padding: 0px !important;
    background-color: transparent !important;
    height: 30px;
    font-size: 10pt;
  }
}

.weekview-normal-event-table {

  .calendar-event-inner {
    background-color: #008489c4 !important;
    border-left: 2px solid #008489;
  }
}

.weekview-allday-table {
  .calendar-event-inner {
    background-color: #008489c4 !important;
    border-left: 2px solid #008489;
  }
}

pinch-zoom {
  overflow: unset !important;
}

.datePickerBox {
  width: 180px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border: solid 1px #dcdcdc;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px !important;
  border-radius: 5px;
  cursor: pointer;
}

.datePickerBox:hover {
  background-color: #ececec;
}

.datePickerBox i {
  font-size: 13pt;
  margin-right: 10px;
  line-height: 2.2;
  color: #254453;
}

.form-control {
  background-color: transparent !important;
}

.form-control-outline {
  border-bottom: 1px solid #ced4da;

  &:focus {
    outline: none;
    border-bottom: 1px solid #008489;
  }
}

.XSWidthInput {
  max-width: 50px;
  width: 100%;
  font-size: 9pt;
  font-weight: 300;
  height: 25px;
}

.LWidthInput {
  max-width: 200px;
  width: 100%;
}

.MDWidthInput {
  max-width: 100px;
  width: 100%;
  font-size: 13px;
  font-weight: 300;
  height: 40px;
}

.XLWidthInput {
  max-width: 300px;
  width: 100%;
  font-size: 13px;
  font-weight: 300;
  height: 40px;
}

.mydp-date {
  border: none !important;
}

/*Swipe*/
.swipe {
  font-size: medium;
  opacity: 0.7;
  position: relative;
  bottom: 8px;
  left: 5px;
}

.swipeDown {
  font-size: 12pt !important;
}


@keyframes heartFadeInOut {
  0% {
    opacity: 1;
  }

  5% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.90;
  }

  15% {
    opacity: 0.85;
  }

  20% {
    opacity: 0.80;
  }

  25% {
    opacity: 0.75;
  }

  30% {
    opacity: 0.70;
  }

  35% {
    opacity: 0.65;
  }

  40% {
    opacity: 0.60;
  }

  45% {
    opacity: 0.55;
  }

  50% {
    opacity: 0.50;
  }

  55% {
    opacity: 0.45;
  }

  60% {
    opacity: 0.40;
  }

  65% {
    opacity: 0.35;
  }

  70% {
    opacity: 0.30;
  }

  75% {
    opacity: 0.25;
  }

  80% {
    opacity: 0.20;
  }

  85% {
    opacity: 0.15;
  }

  95% {
    opacity: 0.10;
  }

  100% {
    opacity: 0;
  }
}

.gm-style-iw-d {
  -webkit-overflow-scrolling: auto !important;
}

.swipe {
  padding: 0 10px 0 10px;
  animation-name: heartFadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-direction: alternate;

}

.areYouAnOwner {
  padding: 10px;
  background-color: white;
  color: #1e8489;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
}

.areYouAnOwner:hover {
  color: #32b496;
}

.areYouASeeker {
  padding: 10px;
  background-color: white;
  color: #ec6f6f;
  font-weight: bold;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
}

.areYouASeeker:hover {
  color: #32b496;
}

.fb_reset>div {
  bottom: 100pt !important;
}



// ******* ROOMLESS ACCORDION ******

.roomlessAccordtion {
  padding: 8px;
  font-size: 11pt;
  background-color: #f9f9f9;
  font-weight: 300;
  border-radius: 5px;
  margin-bottom: 10px;
}

.roomlessAccordtion div {
  cursor: pointer;
  padding-bottom: 5px;
  font-weight: 500;
}

.roomlessAccordtion a {
  cursor: pointer;
  font-weight: bold;
  color: #008489;
}

.roomlessAccordtion p {
  padding-left: 1px;
  font-weight: 300;
  font-size: 9pt;
  padding-left: 5px;
}


// ******* ROOMLESS CARD ******

.roomlessCardNoAnimation {
  border-radius: 8px;
  padding: 15px;
  padding-bottom: 30px;
  -webkit-box-shadow: 0 3px 5px 0 rgb(51 51 51 / 8%);
  box-shadow: 0 3px 5px 0 rgb(51 51 51 / 8%);
  background-color: #ffffff;
  overflow: hidden;
  margin: 5px;
}

.roomlessCard {
  border-radius: 8px;
  -webkit-box-shadow: 0 8px 16px 0 rgba(51, 51, 51, .08);
  box-shadow: 0 8px 16px 0 rgba(51, 51, 51, .08);
  background-color: #ffffff;
  overflow: hidden;
}

.roomlessCard:hover {
  transform: scale(1.025);
}

.roomlessCard a {
  text-decoration: none !important;
}

.roomlessCardDesc {
  color: #7a7a7a;
  font-weight: 400;
  padding: 15px;
  font-size: 10pt;
  line-height: 1.4;
}

.roomlessCardDesc h4 {
  margin-top: 0;
  color: black;
}

.roomlessCardContentPadding {
  padding: 25px 10px;
}

.horizontalCardcontainer {
  display: block;
}

.flipAnimation {
  background-color: transparent;
  perspective: 1000px;
  /* Remove this if you don't want the 3D effect */
  height: 100px;
  width: 100%;
  display: flex;
}

.flipAnimation-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  border-radius: 16px;
}

.flipAnimation:hover .flipAnimation-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flipAnimation-front,
.flipAnimation-back {
  position: absolute;
  background-color: white;
  width: 100%;
  box-shadow: 0 8px 16px 0 rgba(51, 51, 51, 0.08);
  border-radius: 16px;
  font-size: 8pt;
  height: 100%;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
}

.flipAnimation-front img {
  padding: 20px;
  width: auto;
  height: 100%;
  max-width: fit-content !important;
  background-color: white;
  border-radius: 16px;
}

.flipAnimation-back div {
  padding: 10px;
  background-color: white;
  border-radius: 16px;
  height: 100%;
}

/* Style the back side */
.flipAnimation-back {
  padding: 15px;
  background-color: white;
  transform: rotateY(180deg);
}


@media screen and (min-width: 770px) {
  .horizontalCardcontainer {
    display: flex;
  }

  .roomlessCardContentPadding {
    padding: 40px 80px;
  }
}

// ******* END - ROOMLESS CARD ******


// ******* ROOMLESS BUTTONS ********


.generalRoomlessBtb {
  text-transform: initial;
  width: 100%;
  padding: 10px;
  color: #ffffff;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  text-decoration: none !important;
  cursor: pointer;
}

.generalRoomlessBtb:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 8px;
}

.generalRoomlessBtb:disabled {
  opacity: 0.5;
  box-shadow: none !important;
}


.generalFlatRoomlessBtb {
  text-transform: initial;
  width: 100%;
  padding: 10px;
  color: #ffffff;
  background-color: #343434;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  text-decoration: none !important;
  cursor: pointer;
}

.generalFlatRoomlessBtb:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 8px;
}

.generalFlatRoomlessBtb:disabled {
  opacity: 0.5;
  box-shadow: none !important;
}

//button for block a rental
.rentBlockBtn {
  font-size: 13pt;
  border: solid 1px #f2f2f2;
  padding: 10px;
  color: #ec6e70;
  background-color: #ffffff;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 0 2px 8px;
  border-radius: 50px;
  margin-top: 10px;
}

.rentBlockBtn:hover {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 8px !important;
}

.rentBlockBtn span {
  font-size: 11pt;
}


// ******* END - ROOMLESS BUTTONS ********


/* Breadcrumbs */
.mainBread {
  font-size: 9pt;
  display: flex;
  list-style: none;
  padding: 5px;
  margin: 0;
}

.li-bread {
  padding: 0 3px;
  color: #343434 !important;
}

.li-bread a {
  color: #343434 !important;
}

@media screen and (min-width: 770px) {
  .mainBread {
    font-size: 12pt;
    display: flex;
    list-style: none;
    padding: 5px;
    margin: 0;
  }
}

/* Paging with no numbers */

.navigationBar {
  width: 100%;
  display: flex;
}

.navigationBar div {
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 48px;
  display: inline;
  text-align: center;
  flex: 1 1 0%;
}

.navigationBar button {
  -webkit-box-pack: center;
  -webkit-box-align: center;
  -webkit-appearance: none;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: white;
  background-color: #343434;
  cursor: pointer;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-clip: padding-box;
  box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgb(0 0 0 / 18%) 0px 2px 4px;
  width: 100%;
  height: 42px;
  border-radius: 20px;
  -o-border-image: initial;
  border-image: initial;
  outline: 0px;
  margin: 0px;
  padding: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.2s ease 0s, -ms-transform 0.25s ease 0s, transform 0.25s ease 0s;
}

.navigationBar button:active {
  background-color: #e5e5e5 !important;
}

.navigationBar button:disabled {
  color: #d5d5d5 !important;
  opacity: 0.2 !important;
}


.menuInline {
  font-weight: 400;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 12pt;
  text-align: center;
  text-transform: uppercase;
  opacity: 1;
  padding: 10px 15px;
  color: #999999;
  cursor: pointer;
}

.menuInline:hover {
  background-color: #eeeeee;
}

.SelectedInline {
  color: #484848 !important;
}

.SelectedInline::after {
  content: "";
  position: relative;
  top: 10px;
  display: block;
  height: 3px;
  background-image: linear-gradient(to right, rgb(8, 199, 129), rgb(0, 191, 179));
  border-radius: 1.2px;
}

.activeListTypeBtn {
  color: white !important;
  background-color: #368187 !important;
}

.numberPrefixBox {
  display: inline-flex;
  padding: 0 5px;
  border: solid 1px #dcdcdc;
  box-shadow: rgb(0 0 0 / 6%) 0px 2px 4px 0px !important;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

/* PHONE NUMBER WITH PREFIX */
.numberPrefixBox #prefixField {
  height: 40px;
  line-height: 40px;
  min-width: 175px;
  width: 60%;
}

.numberPrefixBox #phoneField {
  height: 40px;
  line-height: 40px;
  border-left: solid 1px gainsboro;
  padding-left: 10px;
  min-width: 50px;
  width: 40%;
}

.numberPrefixBox #phoneField input {
  margin: 0;
  border: 0;
  padding-left: 10px;
  width: calc(100% - 15px);
  background-color: transparent;
}

.d-flex {
  display: block !important;
}

.select-dropdown li img {
  width: 20px !important;
  height: 20px !important;
  margin-top: 8px !important;
  margin-right: 2px !important;
}

/* END - PHONE NUMBER WITH PREFIX */


.fromPrice {
  font-size: 12pt;
  text-transform: lowercase;
  font-style: inherit;
}


/* Material datepicker */
.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}


.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-bottom: 0 !important;
  padding-top: 10px !important;
}

.mat-mdc-text-field-wrapper {
  height: 40px !important;
  background-color: transparent !important;
}


.mat-mdc-form-field-infix {
  min-height: 40px !important;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  padding-top: 5px;
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  padding-left: 5px;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

/*Accordion MDB*/

.accordion .card .card-header {
  padding: 10px 15px;
}

.md-accordion .card .card-body {
  color: black !important;
  padding: 5px 30px;
}

.accordionBody {
  border: solid 1px lightgrey;
  border-top: none;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: white;
}

.mdb-accordion-indicator:after {
  border-color: #adacac;
}


.accordion .card .card-header {
  border-radius: 6px;
}

.is-collapsed {
  .card-header {
    border: solid 1px lightgrey !important;
    background-color: white;
  }
}

.active {
  .card-header {
    background-color: rgb(245, 245, 245) !important;
    border: solid 1px lightgrey;
    border-bottom: none !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}

.personal-nav {
  position: relative;
  background: #fff;
  width: 100%;
  margin: auto;
  padding: 0;
  margin-top: 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
}

.personal-nav .item {
  /*  .item-content {
    border-radius: 5px
  }*/

  &:first-child .item-content {
    border-radius: 5px 5px 0 0;
  }

  &:last-child .item-content {
    border-radius: 0 0 5px 5px;
  }

  i {
    margin-right: 3px;
  }

  &:hover {
    cursor: pointer;
    color: #484848;
    background: #efefef;
  }

  &.selected {
    color: #484848;
  }

  &.selected .item-content {
    background: #efefef;
    color: #484848;
    border-left: 5px solid #03c2a1;
  }
}

.personal-nav .item .item-content {
  width: 100%;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  display: block;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  color: #999999;
  transition: all 0.2s linear;

  .item-icon {
    display: inline-flex;
    width: 20px;
  }
}

.personal-nav .item .item-content {
  border-left: #999999 5px solid;
}


mdb-select>div>div {

  &.single,
  &.multiple {
    padding-top: 0px !important;
  }
}

.user-info-chip-container {

  mdb-select {
    width: 100% !important;
    min-width: 185px !important;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 8px !important;
    }

    @media only screen and (max-width: 767px) {
      width: 100% !important;
      min-width: unset !important;
      padding: 5px 0;
      border-radius: 30px;

    }


    &mdb-select>div>div.single>div.clear:hover {
      background-color: transparent !important;
    }

    border: 1px solid;
    border-radius: 15px;
    font-family: unset !important;

    & * {
      font-family: unset !important;
    }

    >div>div.single {
      border-bottom: none !important;


      >div.placeholder,
      >div.value {
        line-height: unset !important;
        padding-left: 30px;

        @media only screen and (max-width: 767px) {
          padding-left: 40px;
        }

        color: black !important;
        font-size: 12px !important;
      }

      >div.value {
        max-width: 85%;
        font-family: unset !important;
      }
    }

    .mdb-select-toggle:before {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
  }

}

.user-recent-activities-content {
  .payment-column-main-container {
    font-size: 10pt !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .rent-info-column,
    .payment-column {
      flex: 1;
      max-width: 200px;

      @media only screen and (max-width: 767px) {
        width: 50%;
      }
    }

    .system-notes-column {
      display: flex !important;

      @media only screen and (max-width: 767px) {
        display: unset !important;
      }

      flex-direction: column;

      & .noteField {
        border-radius: 6px;
        height: unset;
        min-height: 31px;

        @media only screen and (max-width: 767px) {
          min-width: unset !important;
        }
      }
    }
  }
}

.add-followup-container,
.add-visit-container {
  .mdc-notched-outline {
    height: 36px !important;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 7px !important;
    padding-left: 6px;
  }

  .mat-mdc-form-field-infix {
    min-height: 36px !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

}

.adminListingTable .mat-date-range-input-container {
  font-size: 16px !important;
}

app-user-card-activities:last-of-type .user-recent-activities-content .user-recent-activities-row {
  border-bottom: none;
}

.payment-column-main-container .tooltip-inner {
  max-width: 100% !important;
}
