// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$gray-palette);
$app-accent: mat.define-palette(mat.$teal-palette, 700, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme((color: (primary: $app-primary,
        accent: $app-accent,
        warn: $app-warn,
      )));
// Apply the theme to your component

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

// Tailwind init
@tailwind base;
@tailwind components;
@tailwind utilities;

/*Used for MDB styling*/
/* mdb color styling */

.btn-secondary {
  background-color: #008489 !important;
}

/* mdb-select styling */
mdb-select-dropdown .dropdown-content li>a,
mdb-select-dropdown .dropdown-content li>span {
  color: #008489 !important;
}

/*
mdb-select-dropdown .dropdown-content{
  position: relative !important;
}
*/
mdb-select>div>div.single>div.placeholder {
  font-weight: 100 !important;
}

mdb-select>div>div.single>div.value {
  color: #1e8489 !important;
}

.mat-mdc-tooltip {
  font-size: 9pt !important;
}

.md-form input[type=text]:focus:not([readonly]),
.md-form input[type=password]:focus:not([readonly]),
.md-form input[type=email]:focus:not([readonly]),
.md-form input[type=url]:focus:not([readonly]),
.md-form input[type=time]:focus:not([readonly]),
.md-form input[type=date]:focus:not([readonly]),
.md-form input[type=datetime-local]:focus:not([readonly]),
.md-form input[type=tel]:focus:not([readonly]),
.md-form input[type=number]:focus:not([readonly]),
.md-form input[type=search-md]:focus:not([readonly]),
.md-form input[type=search]:focus:not([readonly]),
.md-form textarea.md-textarea:focus:not([readonly]) {
  box-shadow: 0 1px 0 0 #008489 !important;
  border-bottom: 1px solid #008489 !important;
}

:host(.in-item) {
  margin: 0px !important;
}

.form-check-input[type=checkbox]:checked+label:before,
label.btn input[type=checkbox]:checked+label:before {
  border-right: 2px solid #008489;
  border-bottom: 2px solid #008489;
}

.pagination.pg-blue .page-item.active .page-link {
  background-color: #368187;
}

.pagination.pg-blue .page-item.active .page-link:hover {
  background-color: #ec656f;
}


/* mdb-switch styling */

.switch .secondary-switch label input[type=checkbox]:checked+.lever {
  background-color: #41a9af;
}

.switch .secondary-switch label input[type=checkbox]:checked+.lever:after {
  background-color: #368187;
}

/* mdb-date-picker*/

.md-form {
  margin: 0 !important;
  border-bottom: solid 1px #e0dfe0;
}

.picker div {
  /*Per nascondere il bordo nell'input del calendario ma non per gli altri input normali*/
  border-bottom: 0 !important;
}

.mydp {
  z-index: 999 !important;
}

.picker--opened .picker__frame {
  border-radius: 10px !important;
}

.picker__box .picker__header .picker__date-display {
  background-color: white !important;
  border-radius: 10px !important;
}


.picker__box .picker__table .picker--focused,
.picker__box .picker__table .picker__day--selected,
.picker__box .picker__table .picker__day--selected:hover {
  background-color: #595959 !important;
}

.picker__day-display,
.picker__month-display,
.picker__weekday-display {
  zoom: 0.7;
  margin-top: 25px !important;
  color: #595959 !important;
}

.picker__box .picker__header .picker__date-display .picker__year-display {
  color: rgba(89, 89, 89, 0.56) !important;

}

.picker__box .picker__footer .picker__button--clear,
.picker__box .picker__footer .picker__button--close,
.picker__box .picker__footer .picker__button--today {
  font-weight: normal !important;

}

.picker__nav--next {
  color: #595959 !important;
}

.picker__nav--prev {
  color: #595959 !important;
}


/* mdb-accordion */

/*

mdb-accordion-item-head .card-header a {
  color: #048489 !important;
}

.accordion .card .card-header {
  color: #048489 !important;
  padding: 5px 10px 10px 10px;
  border-bottom: solid 1px whitesmoke;
}

*/


h5 {
  margin-top: 0 !important;
}

.accordionHeader {
  border: 0 !important;
}

.md-accordion {
  border-bottom: inherit;
}

mdb-accordion-item .card {
  border: none !important;
}

.mdb-accordion-indicator::after {
  border-width: 0 1px 1px 0 !important;
}

.accordionFAQsContainer {
  text-align: left;
  color: black;
}

.accordionFAQsContainer .mb-0 {
  color: black !important;
  font-weight: 300;
  font-size: 11pt;
}

.accordionFAQsHeader {
  border: none !important;
  border-radius: 0 !important;
  padding: 17px 7px !important;
}

.accordionFAQsText {
  background-color: #f7f7f7;
  padding: 25px !important;
  text-align: justify;
}

.accordionDocHeader {
  border-radius: 0 !important;
  padding: 15px 10px 5px 10px !important;
  min-height: 35px;
}

.accordionDocHeader i {
  top: 13px !important;
}

.accordionDocText {
  background-color: #fafafa;
  padding: 25px !important;
  text-align: justify;
}

.tooltip-inner {
  font-weight: 700;
}

/*table*/
.pl-4,
.px-4 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

/* ion-fab-button */
.ion-color-light {
  border: none !important;
}

/* open-vidu */
#local-video-undefined {
  width: 120px;
}

/* mdb-accordion */
.mb-0 {
  margin-top: 5px;
}

/* pinch-zoom */
.imgZoom {
  height: 100%;
  bottom: 100px;
}

@media screen and (min-width: 770px) {
  .imgZoom {
    bottom: 0px !important;
  }
}

// Shared classes

.sectionTitleFilter {
  font-size: 20px;
  letter-spacing: normal !important;
  color: rgb(72, 72, 72) !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding: 5px;
  margin-bottom: 10px;
  line-height: 26px !important;
}

.page-item.disabled .page-link {
  background-color: #f1f1f1 !important;
}



html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.only-border {
  color: transparent;
  border: 1px solid gray;
  border-radius: 50px;
}